var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-container',{staticClass:"shadow mb-5",attrs:{"fluid":""}},[_c('h1',{staticClass:"text-center my-4"},[_vm._v("Keywords✔️")]),(_vm.isBusy && _vm.spinner)?_c('div',{staticClass:"text-center mb-5"},[_c('b-row',{staticClass:"justify-content-md-center my-5 pb-5",attrs:{"type":"grow"}},[_c('b-spinner',{staticStyle:{"width":"25rem","height":"25rem"},attrs:{"variant":"dark","label":"keywords"}})],1)],1):_vm._e(),_c('b-row',{staticClass:"shadow"},[_c('b-col',{staticClass:"my-1",attrs:{"lg":"12"}},[_c('b-form-group',[_c('b-input-group',{attrs:{"size":"sm","squared":""}},[_c('b-form-input',{attrs:{"id":"filter-keywords-input","type":"search","placeholder":"Type to Search"},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1)],1)],1)],1),_c('b-row',{staticClass:"shadow"},[_c('b-table',{ref:"items",attrs:{"items":_vm.items,"fields":_vm.fields,"current-page":_vm.currentPage,"per-page":_vm.perPage,"filter":_vm.filter,"filter-included-fields":_vm.filterOn,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"sort-direction":_vm.sortDirection,"stacked":"md","show-empty":"","small":"","striped":"","hover":"","sticky-header":"","primary-key":"id","label-sort-asc":"","label-sort-desc":"","label-sort-clear":""},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"filtered":_vm.onFiltered},scopedSlots:_vm._u([{key:"cell(actions)",fn:function(row){return [_c('b-button',{attrs:{"size":"sm","squared":""},on:{"click":function($event){$event.stopPropagation();_vm.getKeywordData(row.item);
              row.toggleDetails();}}},[_vm._v(" "+_vm._s(row.detailsShowing ? "Hide" : "Show")+" Details ")]),_c('b-button',{attrs:{"size":"sm","squared":"","variant":"danger"},on:{"click":function($event){return _vm.$router.push(
                '/taxonomy/keyword/' +
                  row.item.keyword +
                  '/' +
                  row.item.language_code +
                  '/' +
                  row.item.location_code +
                  '/' +
                  row.item.device +
                  '/' +
                  '10' +
                  '/' +
                  'false'
              )}}},[_vm._v(" analyze ")])]}},{key:"location_code-remover",fn:function(row){return [_c('b-card',{attrs:{"header":"Details"}},[_c('b-list-group',_vm._l((row.item),function(values,key){return _c('b-list-group-item',{key:key,attrs:{"href":"#"}},[(
                    key != '_showDetails' &&
                    key != 'concepts' &&
                    key != 'entities' &&
                    key != 'categories' &&
                    key != 'keywords'
                  )?_c('span',[_vm._v(" "+_vm._s(key)+": "+_vm._s(values))]):(
                    key == 'concepts' ||
                    key == 'entities' ||
                    key == 'categories' ||
                    key == 'keywords'
                  )?_c('ul',[_c('lh',[_c('h5',[_vm._v(_vm._s(key)+":")])]),_vm._l((values),function(item){return _c('li',{key:item},[_vm._v(" "+_vm._s(item)+" ")])})],2):_vm._e()])}),1),_c('p',{staticClass:"card-text mt-2"})],1),_vm._v(" "+_vm._s(row.item)+" ")]}},{key:"row-details",fn:function(row){return [_c('b-card',{attrs:{"header":"Details"}},[_c('b-list-group',_vm._l((row.item),function(values,key){return _c('b-list-group-item',{key:key,attrs:{"href":"#"}},[(
                    key != '_showDetails' &&
                    key != 'concepts' &&
                    key != 'entities' &&
                    key != 'categories' &&
                    key != 'keywords'
                  )?_c('span',[_vm._v(" "+_vm._s(key)+": "+_vm._s(values))]):(
                    key == 'concepts' ||
                    key == 'entities' ||
                    key == 'categories' ||
                    key == 'keywords'
                  )?_c('ul',[_c('lh',[_c('h5',[_vm._v(_vm._s(key)+":")])]),_vm._l((values),function(item){return _c('li',{key:item},[_vm._v(" "+_vm._s(item)+" ")])})],2):_vm._e()])}),1),_c('p',{staticClass:"card-text mt-2"})],1)]}}])})],1),_c('b-row',{staticClass:"py-3 shadow"},[_c('b-col',{attrs:{"sm":"5"}},[_c('b-dropdown',{attrs:{"variant":"dark","size":"sm","text":'Per page: ' + _vm.perPage,"id":"per-page-select","name":"per-page-select"},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}},_vm._l((_vm.pageOptions),function(option){return _c('b-dropdown-item-button',{key:option,attrs:{"value":option},on:{"click":function($event){_vm.perPage = option}}},[_vm._v(" "+_vm._s(option)+" ")])}),1)],1),_c('b-col',{attrs:{"sm":"7","md":"6"}},[_c('b-pagination',{staticClass:"my-0",attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage,"align":"fill","size":"sm","squared":""},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1),_c('b-modal',{attrs:{"id":_vm.infoModal.id,"title":_vm.infoModal.title,"ok-only":""},on:{"hide":_vm.resetInfoModalKeyword}},[_c('pre',[_vm._v(_vm._s(_vm.infoModal.content))])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }