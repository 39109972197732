<template>
  <div>
    <b-container fluid class="shadow mb-5">
      <h1 class="text-center my-4">Keywords✔️</h1>

      <!-- spinner -->
      <div class="text-center mb-5" v-if="isBusy && spinner">
        <b-row type="grow" class="justify-content-md-center my-5 pb-5">
          <b-spinner
            variant="dark"
            style="width: 25rem; height: 25rem"
            label="keywords"
          ></b-spinner>
        </b-row>
        <!-- <span class="sr-only">Loading ...</span> -->
      </div>

      <!-- <div class="shadow m-3" v-if="!isBusy"> -->
      <b-row class="shadow">
        <b-col lg="12" class="my-1">
          <b-form-group>
            <b-input-group size="sm" squared>
              <b-form-input
                id="filter-keywords-input"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              ></b-form-input>

              <!-- <b-input-group-append>
                <b-button :disabled="!Keywords" @click="Keywords = ''"
                  >Clear</b-button
                >
              </b-input-group-append> -->
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="shadow">
        <!-- keywords table -->
        <!-- {{ items }} -->
        <b-table
          ref="items"
          :items="items"
          :fields="fields"
          :current-page="currentPage"
          :per-page="perPage"
          :filter="filter"
          :filter-included-fields="filterOn"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          stacked="md"
          show-empty
          small
          striped
          hover
          sticky-header
          primary-key="id"
          label-sort-asc=""
          label-sort-desc=""
          label-sort-clear=""
          @filtered="onFiltered"
        >
          <template #cell(actions)="row">
            <!-- button show details -->
            <b-button
              size="sm"
              squared
              @click.stop="
                getKeywordData(row.item);
                row.toggleDetails();
              "
            >
              {{ row.detailsShowing ? "Hide" : "Show" }} Details
            </b-button>

            <!-- analyze button -->
            <b-button
              size="sm"
              squared
              variant="danger"
              @click="
                $router.push(
                  '/taxonomy/keyword/' +
                    row.item.keyword +
                    '/' +
                    row.item.language_code +
                    '/' +
                    row.item.location_code +
                    '/' +
                    row.item.device +
                    '/' +
                    '10' +
                    '/' +
                    'false'
                )
              "
            >
              analyze
            </b-button>
          </template>

          <template #location_code-remover="row">
            <b-card header="Details">
              <b-list-group>
                <b-list-group-item
                  v-for="(values, key) in row.item"
                  :key="key"
                  href="#"
                >
                  <span
                    v-if="
                      key != '_showDetails' &&
                      key != 'concepts' &&
                      key != 'entities' &&
                      key != 'categories' &&
                      key != 'keywords'
                    "
                  >
                    {{ key }}: {{ values }}</span
                  >

                  <ul
                    v-else-if="
                      key == 'concepts' ||
                      key == 'entities' ||
                      key == 'categories' ||
                      key == 'keywords'
                    "
                  >
                    <lh><h5>{{ key }}:</h5></lh>
                    <li v-for="item in values" :key="item">
                      {{ item }}
                    </li>
                  </ul>
                </b-list-group-item>
              </b-list-group>
              <p class="card-text mt-2"></p>
            </b-card>
            {{ row.item }}
          </template>

          <!-- keyword details -->
          <template #row-details="row">
            <b-card header="Details">
              <b-list-group>
                <b-list-group-item
                  v-for="(values, key) in row.item"
                  :key="key"
                  href="#"
                >
                  <span
                    v-if="
                      key != '_showDetails' &&
                      key != 'concepts' &&
                      key != 'entities' &&
                      key != 'categories' &&
                      key != 'keywords'
                    "
                  >
                    {{ key }}: {{ values }}</span
                  >

                  <ul
                    v-else-if="
                      key == 'concepts' ||
                      key == 'entities' ||
                      key == 'categories' ||
                      key == 'keywords'
                    "
                  >
                    <lh
                      ><h5>{{ key }}:</h5></lh
                    >
                    <li v-for="item in values" :key="item">
                      {{ item }}
                    </li>
                  </ul>
                </b-list-group-item>
              </b-list-group>
              <p class="card-text mt-2"></p>
            </b-card>
          </template>
        </b-table>
      </b-row>

      <!-- keywords table pagination -->
      <b-row class="py-3 shadow">
        <b-col sm="5">
          <b-dropdown
            variant="dark"
            size="sm"
            :text="'Per page: ' + perPage"
            id="per-page-select"
            name="per-page-select"
            v-model="perPage"
          >
            <b-dropdown-item-button
              v-for="option in pageOptions"
              :key="option"
              :value="option"
              @click="perPage = option"
            >
              {{ option }}
            </b-dropdown-item-button>
          </b-dropdown>
        </b-col>

        <!-- pagination -->
        <b-col sm="7" md="6">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            squared
            class="my-0"
          ></b-pagination>
        </b-col>
      </b-row>

      <!-- InfoKeyword modal -->
      <b-modal
        :id="infoModal.id"
        :title="infoModal.title"
        ok-only
        @hide="resetInfoModalKeyword"
      >
        <pre>{{ infoModal.content }}</pre>
      </b-modal>
    </b-container>
  </div>
</template>

<script>
import { TaxonomyService } from "../services/taxonomy/taxonomy.service";

export default {
  name: "Taxonomy",
  components: {},
  data() {
    return {
      isBusy: true,
      spinner: false,

      //keywords
      items: [],
      fields: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 20,
      pageOptions: [20, 50, 100, 1000],
      sortBy: "id",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-keywords-modal",
        title: "",
        content: "",
      },
    };
  },

  // watch: {
  // },

  computed: {},

  async beforeMount() {
    this.isBusy = true;
    this.spinner = true;
    const { data: data } = await TaxonomyService.getKeywords();

    const { data: locations } = await TaxonomyService.getLocations();
    console.log(locations);

    //Country name instead location_code
    data.forEach(async (item) => {
      item.location = locations.find(
        (location) => location.code == item.location_code
      ).name;
      console.log(item);
    });

    Object.keys(data[0]).forEach(async (column) => {
      if (column != "serps" && column != "location_code") {
        this.fields.push({
          key: column,
          label: column,
          sortable: true,
          sortDirection: "desc",
          class: "text-center",
        });
      }
    });
    this.fields.push({ key: "actions", label: "Actions" }); //for table
    this.totalRows = data.length;

    this.items = data;

    this.isBusy = false;
    this.spinner = false;

    return;
  },

  // async created() {
  //   await this.getChartKeywordsData();
  //   // this.locations = await this.getLocations();
  // },

  // async mounted() {
  //   await this.getChartKeywordsData();
  //   // await this.getMoreItems();
  // },

  methods: {
    async infoModalKeyword(item, index, button) {
      const keywordData = await TaxonomyService.getKeyword({ id: item.id });
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(keywordData.data, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModalKeyword() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    async getKeywordData(item) {
      const keywordData = await TaxonomyService.getKeyword({ id: item.id });
      console.log(keywordData);

      const concepts = [
        ...new Set(
          keywordData.data
            .sort((a, b) =>
              a.concept_relevance < b.concept_relevance
                ? 1
                : b.concept_relevance < a.concept_relevance
                ? -1
                : 0
            )
            .map((item) => item.concept + " | " + item.concept_relevance)
        ),
      ];

      const entities = [
        ...new Set(
          keywordData.data
            .sort((a, b) =>
              a.entity_count < b.entity_count
                ? 1
                : b.entity_count < a.entity_count
                ? -1
                : 0
            )
            .map(
              (item) =>
                item.entity +
                " | " +
                item.entity_relevance +
                " | " +
                item.entity_count
            )
        ),
      ];
      const categories = [
        ...new Set(
          keywordData.data
            .sort((a, b) =>
              a.category_score < b.category_score
                ? 1
                : b.category_score < a.category_score
                ? -1
                : 0
            )
            .map((item) => item.category + " | " + item.category_score)
        ),
      ];
      const keywords = [
        ...new Set(
          keywordData.data
            .sort((a, b) =>
              a.keyword_count < b.keyword_count
                ? 1
                : b.keyword_count < a.keyword_count
                ? -1
                : 0
            )
            .map(
              (item) =>
                item.keyword_keyword +
                " | " +
                item.keyword_relevance +
                " | " +
                item.keyword_count
            )
        ),
      ];
      item.concepts = concepts;
      item.entities = entities;
      item.categories = categories;
      item.keywords = keywords;
      await this.$refs.items.refresh();
      // item.concepts = concepts;
      // console.log(concepts);
      // return { concepts, entities, categories, keywords };
      return;
    },
  },
};
</script>